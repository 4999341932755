import axios, { AxiosHeaderValue } from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Result from "../components/PositionLogResult";
import Header from "../components/Header";
import PageSelect from "../components/PageSelect";
import ExcelJS from 'exceljs';
import Loading from "../components/Loading";
import PostionLoading from "../components/PositionLoading";
import positionResultType from "../model/positionResultType";
import clusterMasterResultType from "../model/ClusterMasterResultType";

const PositionLog = () => {

    const [apiKey, setApikey] = useState<string>("");
    const [mounted, setMounted] = useState<string>("beacon");
    const [deviceid, setDeviceId] = useState<string>("");
    const [beacon_id, setBeacon_Id] = useState<string>("");
    const [per_page, setPer_Page] = useState<string>("");
    const [cluster_id, setCluster_Id] = useState<string>("");
    const [detected_gt, setDetcted_gt] = useState<string>("");
    const [detected_lt, setDetcted_lt] = useState<string>("");
    const [sort, setSort] = useState<string>("");
    const [pageNo, setPageNo] = useState<number>(0);
    const [lastPageNo, setLastPageNo] = useState<number>(0);
    const [period, setPeriod] = useState<string>("1")
    const [total, setTotal] = useState<number>(-1);
    const [result, setResult] = useState<positionResultType[]>([]);
    const results: positionResultType[] = [];
    const [csvResult, setCSVResult] = useState<positionResultType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPositionLoading, setIsPositionLoading] = useState<boolean>(false);
    const [isNoCheck, setNoCheck] = useState<boolean>(false);
    var apiUrl:string = "";
    let clusterApiUrl: string = ""
    let clusterApikey: string = ""
    const clusterResults: clusterMasterResultType[] = [];
    const [clusterResult, setClusterResult] = useState<clusterMasterResultType[]>([]);
    const CLUSTER_PLACEFORDER: string = "クラスタを選択して下さい";

    const checkLocoEnv = () => {
      if (localStorage.getItem("locoEnv") === "prod") {
        apiUrl = "https://api.bc-loco.jp/api/position_logs"
        clusterApiUrl = "https://api.bc-loco.jp/api/clusters"
      } else {
        apiUrl = "http://stg.api.bc-loco.jp/api/position_logs"
        clusterApiUrl = "http://stg.api.bc-loco.jp/api/clusters"
      }
    }

    const getCluster = async() => {
      await axios.get(clusterApiUrl,
      {
        headers: {
            "Authorization": clusterApikey,
            "Content-Type": "application/json"
        },
        params: {
          ...({per_page: 1000})
        }
      })
      .then(async res => {
        console.log(res);
        if(res.data.code === 400) {
          alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
          return
        } else if(res.data.code === 401) {
          alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
          return
        }
        const items = JSON.parse(JSON.stringify(res.data.payload))
        console.log(items)

        clusterResult.length = 0
        items.data.forEach((value: clusterMasterResultType) => {
          clusterResults.push(value)
        })

        setClusterResult(clusterResults)

        const selectCluster = document.getElementById("cluster") as HTMLSelectElement;

        //Cluster一覧を全て消去
        let maxOptionLength = selectCluster.options.length;
        for ( let idx = maxOptionLength ; idx > 0 ; idx-- ) {
          console.log("maxoption:" + maxOptionLength)
          selectCluster.options.remove(idx)
        }

        //Clusterを設定
        clusterResults.forEach((value: clusterMasterResultType, index: number) => {

          let insertClusterNameflg: Boolean = true;
          for ( let idx = 0 ; idx < selectCluster.options.length ; idx++ ) {
              if (selectCluster.options[idx].textContent === value.name) {
                insertClusterNameflg = false;
              }
          }

          if (insertClusterNameflg) {
            console.log(value.name)
            const option1 = document.createElement("option");
            option1.value = index.toString();
            option1.textContent = value.name;
            selectCluster?.appendChild(option1)
          }
        })
      })
      .catch(err => 
        ClusterError(err)
        //alert("クラスター取得でエラーが発生しました。Staging・Productionの設定は合ってますか？" + err)
      )
  }
    
    const getPositionLog = async(e: any, pageNo: number) => {
        e.preventDefault();
        if (per_page === "") {
          setPer_Page("100")
        }
        saveParams();
        saveApikey();
        checkLocoEnv();
        if (Number(period) > 60 || Number(period) < 1) {
          alert("集計期間は1秒から60秒の範囲で入力してください。")
          return
        }
        if (cluster_id == "") {
          alert("クラスタを選択してください。")
          return
        }
        setIsPositionLoading(true);
        
        await axios.get(apiUrl,
        {
          headers: {
              "Authorization": apiKey,
              "Content-Type": "application/json"
          },
          params: {
            
            ...(mounted ? {mounted: mounted} : {}),
            ...(deviceid ? {device_id: deviceid} : {}),
            ...(cluster_id ? {cluster_id: cluster_id} : {}),
            ...(beacon_id ? {beacon_id: beacon_id} : {}),
            ...(pageNo ? {page: pageNo} : {}),
            ...(per_page ? {per_page: per_page} : {}),
            ...(period ? {period: period} : {}),
            ...(detected_gt ? {detected_gt: detected_gt.replace("T", " ")} : {}),
            ...(detected_lt ? {detected_lt: detected_lt.replace("T", " ")} : {}),
            ...(sort ? {sort: sort} : {})
          }
          
        })
        .then(async res => {
          console.log(res);
          if(res.data.code === 400) {
            alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
            setIsPositionLoading(false);
            return
          } else if(res.data.code === 401) {
            alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
            setIsPositionLoading(false);
            return
          }
          const items = JSON.parse(JSON.stringify(res.data.payload))

          if (items.total > 100000) {
            if (pageNo === 0) {
              alert("10万件を超えるデータは表示出来ません\n10万件までのデータを表示します\n総件数:" + items.total + "\n日付範囲を調整して下さい。")
            }
            
            setTotal(items.total)
            setPageNo(items.current_page)
            setLastPageNo(getLastPageNo())
            //return 
          } else {
            setTotal(items.total)
            setPageNo(items.current_page)
            setLastPageNo(items.last_page)
          }
          
          items.data.forEach((value: positionResultType, index: number) => {
            results.push(value)
            results[index].no = items.from;
            items.from++;
          })
          setIsPositionLoading(false);
          setResult(results)
        })
        .catch(err => 
          PositionError(err)
        )
    }

    const createCSV = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {

      
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet("positionLog");
      const worksheet = workbook.getWorksheet("positionLog");
      const columns: Partial<ExcelJS.Column>[] = [];

      if (isNoCheck) {
        columns.push({ header: "no", key: "no" }) 
      }
      columns.push({ header: "cluster_id", key: "cluster_id" })
      if (mounted === "beacon") {
        columns.push({ header: "device_id", key: "device_id" })
      } else {
        columns.push({ header: "beacon_id", key: "beacon_id" })
      }
      
      columns.push({ header: "nearest", key: "nearest" })
      columns.push({ header: "x", key: "x" })
      columns.push({ header: "y", key: "y" })
      columns.push({ header: "h", key: "h" })
      columns.push({ header: "r", key: "r" })
      columns.push({ header: "detected", key: "detected" })
      
      worksheet.columns = columns;
      setIsLoading(true);
      if (await getAllPositionLog(e, 1) === true) {
        
        csvResult.forEach((value: positionResultType) => {
          worksheet.addRow(value);
        });

        csvResult.length = 0;

        console.log("Row Count:" + worksheet.rowCount)
        setIsLoading(false);

        try {
          const uint8Array = await workbook.csv.writeBuffer();
          const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          setTimeout(()=> {
            a.href = url;
            a.download = "positionlog_" + getNowDate() +  ".csv";
            a.click();
            a.remove();
          },1000)

        } catch (error) {
          console.error(error);
        }
      } else {
        setIsLoading(false);
      }
     
    }

    const getAllPositionLog = async(e: any, pageNo: number) => {
      let ret = true;
      e.preventDefault();
      checkLocoEnv();
      if (Number(period) > 60 || Number(period) < 1) {
        alert("集計期間は1秒から60秒の範囲で入力してください。")
        return
      }
      if (cluster_id == "") {
        alert("クラスタを選択してください。")
        return
      }
      await axios.get(apiUrl,
      {
        headers: {
            "Authorization": apiKey,
            "Content-Type": "application/json"
        },
        params: {
          ...(mounted ? {mounted: mounted} : {}),
          ...(deviceid ? {device_id: deviceid} : {}),
          ...(cluster_id ? {cluster_id: cluster_id} : {}),
          ...(beacon_id ? {beacon_id: beacon_id} : {}),
          ...(pageNo ? {page: pageNo} : {}),
          ...({per_page: 1000}),
          ...(period ? {period: period} : {}),
          ...(detected_gt ? {detected_gt: detected_gt.replace("T", " ")} : {}),
          ...(detected_lt ? {detected_lt: detected_lt.replace("T", " ")} : {}),
          ...(sort ? {sort: sort} : {})
        }
      })
      .then(async res => {
        console.log(res);
        if(res.data.code === 400) {
          alert("条件設定に問題があります。" + JSON.stringify(res.data.payload))
          return ret = false
        } else if(res.data.code === 401) {
          alert("APIKeyが正しく入力されていません。" + JSON.stringify(res.data.message))
          return ret = false
        }
        const items = JSON.parse(JSON.stringify(res.data.payload))
        if (items.total > 100000) {
          if (pageNo === 1) {
            let result = window.confirm("10万件を超えるデータは出力出来ません\n10万件までのデータを出力しますか？\n総件数:" + items.total + "\n日付範囲を調整して下さい。")
            if (result === false) {
              return ret = false
            }
          }
        } else {
          setLastPageNo(items.last_page)
        }
        let csvArrayCount = csvResult.length;
        
        items.data.forEach((value: positionResultType, index: number) => {
          csvResult.push(value);
          csvResult[index + csvArrayCount].no = items.from;
          items.from++;
        })
        setCSVResult(csvResult);
        if (items.last_page > 100) {
          if (items.current_page != 100) {
            await getAllPositionLog(e, items.current_page + 1);
          } else {
            console.log("getall: " + csvResult.length);
          }
        } else {
          if (items.current_page != items.last_page) {
            await getAllPositionLog(e, items.current_page + 1);
          } else {
            console.log("getall: " + csvResult.length);
          }
        }        
      })
      .catch(err => 
        alert("エラーが発生しました。" + err)
      )
      return ret
  }

  const PositionError = (err: any) => {
    setIsPositionLoading(false);
    alert("エラーが発生しました。" + err);
  }

  const ClusterError = (err: any) => {
    setApikey("");
    clearSelectCluster();
    alert("クラスター取得でエラーが発生しました。Staging・Productionの設定は合ってますか？" + err);
  }

  const clearSelectCluster = () => {
    const selectCluster = document.getElementById("cluster") as HTMLSelectElement;
    let maxOptionLength = selectCluster.options.length;
    for ( let idx = maxOptionLength ; idx > 0 ; idx-- ) {
      console.log("maxoption:" + maxOptionLength)
      selectCluster.options.remove(idx)
    } 
  }

  const changeDetectedGt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setDetcted_gt(value);
  }

  const changeDetectedLt = (value: string) => {
    if (value.length >= 1 && value.length < 17) {
      value = value + ":00";
    }
    setDetcted_lt(value);
  }

  const changeMounted = (value: string) => {
    setMounted(value);
    setResult([]);
  }

  const getNowDate = () => {
    let dt = new Date();
    let y = dt.getFullYear();
    let m = ("00" + (dt.getMonth()+1)).slice(-2);
    let d = ("00" + (dt.getDate())).slice(-2);
    let result = y  + m +  d;
    return result;
  }

  const getLastPageNo = () => {
    if (per_page === "") {
      return 100000 / 100
    } else {
      return 100000 / Number(per_page) 
    }
  }

  type positionParamsType = {
    apikey?: string,
    deviceid?: string,
    clusterid?: string,
    beaconid?: string,
    mounted?: string,
    perpage?: string,
    detectedgt?: string,
    detectedlt?: string,
    sort?: string
  }

  const saveParams = () => {
    let params: positionParamsType = {};
    //params.apikey = apiKey ? apiKey : "";
    params.deviceid = deviceid ? deviceid : "";
    params.perpage = per_page ? per_page : "";
    //params.clusterid = cluster_id ? cluster_id : "";
    params.beaconid = beacon_id ? beacon_id : "";
    params.detectedgt = detected_gt ? detected_gt : "";
    params.detectedlt = detected_lt ? detected_lt : "";
    params.sort = sort ? sort : "";

    let json = JSON.stringify(params);
    console.log("params:" + json);
    localStorage.setItem("locoPositionParams", json)

  }

  const saveApikey = () => {
    localStorage.setItem("locoApikey", apiKey ? apiKey : "" );
  }

  const loadParams = () => {
    const paramsJson = localStorage.getItem("locoPositionParams")
    if (paramsJson != null) {
      const params:positionParamsType = JSON.parse(paramsJson as string)
      //setApikey(params.apikey ? params.apikey : "");
      setDeviceId(params.deviceid ? params.deviceid : "");
      setPer_Page(params.perpage ? params.perpage : "")
      //setCluster_Id(params.clusterid ? params.clusterid : "");
      setBeacon_Id(params.beaconid ? params.beaconid : "");
      setDetcted_gt(params.detectedgt ? params.detectedgt : "");
      setDetcted_lt(params.detectedlt ? params.detectedlt : "");
      setSort(params.sort ? params.sort : "");
    }
  }

  const loadApikey = () => {
    const locoApikey = localStorage.getItem("locoApikey")
    if (locoApikey != null) {
      clusterApikey = locoApikey;
      setApikey(locoApikey ? locoApikey : "");
    }
  }

  const setSelectApikey = () => {
    const secretJson = localStorage.getItem("locoSecretArray")
    if (secretJson != null) {
      const secret = JSON.parse(secretJson as string)

      const selectApikey = document.getElementById("apikey") as HTMLSelectElement;
      const option1 = document.createElement("option");
      option1.value = "";
      option1.textContent = "APIKeyを選択して下さい";
      selectApikey?.appendChild(option1)

      secret.forEach((element: { apikey: string; name: string | null; }) => {
          const option1 = document.createElement("option");
          option1.value = element.apikey;
          option1.textContent = element.name;
          selectApikey?.appendChild(option1)
      })
    }
  }

  const selectCluster = (clusteridx: string) => {
    setCluster_Id(clusterResult[Number(clusteridx)].id)
  }

  const selectApikey = (selectApikey: string) => {
    clusterApikey = selectApikey;
    setApikey(selectApikey);
    checkLocoEnv();
    getCluster();
  }

  const changePerPage = (value: string) => {
    setPer_Page(value)
  }

  const changePeriod = (value: string) => {
    setPeriod(value);
  }

  useEffect(() => {
    const secretJson = localStorage.getItem("locoSecretArray")
    if (secretJson != null) {
      setSelectApikey()
    }
    loadParams();
    loadApikey();
    checkLocoEnv();
    getCluster();

  },[])

  return (
      <div>
      <Header current_page="Position"/>
      <div className="card">
          <Form className="font-weight-bold" onSubmit={(e) => getPositionLog(e, 0)}>
            
            <Form.Group as={Row} className="mx-3">
              <Col xs={12} md={4}>
                <Form.Label>ApiKey</Form.Label>
                <Form.Control as="select" id="apikey" value={apiKey as string} placeholder="ApiKeyを入力" onChange={(e) => selectApikey(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Cluster_Id</Form.Label>
                <Form.Control as="select"  className="w-20" id="cluster" onChange={e => selectCluster(e.target.value)}>
                <option>{CLUSTER_PLACEFORDER}</option>
                </Form.Control>
              </Col>
              
              <Col xs={12} md={4}>
                <Form.Label>Per_Page（デフォルト100件/最大1000件）</Form.Label>
                <Form.Control type="text" name="per_page" value={per_page as string}  placeholder="１ページ辺りの最大件数を指定" onChange={(e) => changePerPage(e.target.value)} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mx-3">
              <Col xs={12} md={4}>
                <Form.Label>Mounted （Beacon固定またはDevice固定を指定）</Form.Label>
                <Form.Control as="select" className="w-27" value={mounted as string} onChange={e => changeMounted(e.target.value)} >
                    <option value="beacon">Beacon</option>
                    <option value="device">Device</option>
                </Form.Control>
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Device_Id （MountedがDeviceの場合はこの値は無視されます）</Form.Label>
                {(() => {
                  if (mounted === "beacon") {
                    return (
                      <Form.Control type="text" name="deviceId" value={deviceid as string}  placeholder="Device_Idを入力" onChange={(e) => setDeviceId(e.target.value)} />
                    )
                  } else {
                    return (
                      <Form.Control type="text" name="deviceId" disabled value={deviceid as string}  placeholder="Device_Idを入力" onChange={(e) => setDeviceId(e.target.value)} />
                    )
                  }
                })()}
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Beacon_Id （MountedがBeaconの場合はこの値は無視されます）</Form.Label>
                {(() => {
                  if (mounted === "device") {
                    return (
                      <Form.Control type="text" name="beacon_id" value={beacon_id as string} className="w-27" placeholder="Beacon_Idを入力" onChange={(e) => setBeacon_Id(e.target.value)} />
                    )
                  } else {
                    return (
                      <Form.Control type="text" name="beacon_id" disabled value={beacon_id as string} className="w-27" placeholder="Beacon_Idを入力" onChange={(e) => setBeacon_Id(e.target.value)} />
                    )
                  }
                 })()}
              </Col>
              
            </Form.Group>
            <Form.Group as={Row} className="mx-3">
              <Col xs={12} md={4}>
                <Form.Label>Period（集計期間：デフォルト1秒　最大60秒）</Form.Label>
                <Form.Control type="text" name="period" value={period as string} className="w-27"  onChange={(e) => changePeriod(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Detected_gt</Form.Label>
                <Form.Control type="datetime-local" step="1" name="detected_gt" value={detected_gt as string} className="w-27" placeholder="リージョンイベントの時刻（>=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeDetectedGt(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Detected_lt</Form.Label>
                <Form.Control type="datetime-local" step="1" name="detected_lt" value={detected_lt as string} className="w-27" placeholder="リージョンイベントの時刻（<=）を入力 [YYYY-mm-dd hh:mm:ss]" onChange={(e) => changeDetectedLt(e.target.value)} />
              </Col>
              <Col xs={12} md={4}>
                <Form.Label>Sort（デフォルト: DESC（新着順））</Form.Label>
                <Form.Control as="select" className="w-27" value={sort as string} onChange={e => setSort(e.target.value)} >
                    <option value="">ASC（古い順）またはDESC（新着順）を指定</option>
                    <option value="asc">ASC（古い順）</option>
                    <option value="desc">DESC（新着順）</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <div className="text-center">
              <Form.Group as={Button} type="submit" className="mt-3 mb-2">View PositionLogs</Form.Group>
            </div>
          </Form>
          <Form className="font-weight-bold">
            <div className="text-center">
              <Form.Group as={Button} type="button" className="mt-3 mb-2" onClick={(e) =>  createCSV(e)}>CSV Export</Form.Group>
              <div>
                  <Form.Label>CSV出力の項目に含める</Form.Label> 
                  <label>
                  <input type="checkbox" className='mx-2' onChange={() => setNoCheck(prevState => !prevState)}></input>
                  no</label>
              </div>
            </div>
          </Form>
      </div>
      {(() => {
        if (isLoading) {
          return (
            <div>
              <Loading/>
            </div>
          )
        }
      
      })()}

      {(() => {
        if (isPositionLoading) {
          return (
            <div>
              <PostionLoading/>
            </div>
          )
        }
      
      })()}

      {(() => {
      if (result.length > 0) {
          return  (
            <div>
              <p className="mx-4">TotalLogCount:{total}</p>
              <div className="d-flex justify-content-around">     
                <PageSelect pageNo={pageNo} lastPageNo={lastPageNo} getLog={getPositionLog}></PageSelect>  
              </div>  
              <Result result={result} mounted={mounted}></Result>
            </div>
          );
      } else {
        return (
          <div>
              {
                  total === 0 && <p className="mx-4">TotalLogCount:{total}</p> 
              }
          </div>
          );
      }
      
    })()}
    </div>
  )
}

export default PositionLog